const imagesContext = require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

require('bootstrap');

require('../css/new_site.scss');

const $ = require('jquery');

function updateOccupation(dom) {
    if ($(dom).val() == "employee") {
        $("#form_occupation").val("");
        $("#form_occupation").show();
    } else {
        $("#form_occupation").val($(dom).find("option:selected").html());
        $("#form_occupation").hide();
    }
}


$(document).ready(function() {
    $('[data-toggle="dropdown"]').dropdown();

    $('#nav-menu').on('click', function (event) {
        event.preventDefault();
        $('body').addClass('menu');
    });
    $('#menu-close').on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('menu');
    });

    $(".btn-circle").on("click", function(event) {
        event.preventDefault();

        let width = $(this).closest(".row").parent().next().find(".showcase-item").outerWidth();
        let carousel = $(this).closest(".row").parent().next().find(".items").parent();

        carousel.scroll();
        if ($(this).hasClass("right")) {
            carousel.animate({
                scrollLeft: carousel.scrollLeft() + width
            });
        } else {
            carousel.animate({
                scrollLeft: carousel.scrollLeft() - width
            });
        }
    });


    $("#form_viewing_0, #form_viewing_1").change(function() {
        if ($(this).val() == 1) {
            $('.timeslots').show();
        } else {
            $('.timeslots').hide();
        }
    });

    $("#form_occupationType").each(function() {
        updateOccupation(this);
    })
    $("#form_occupationType").change(function () {
        updateOccupation(this);
    });

    $('#nav-menu').on('click', function (event) {
        event.preventDefault();
        $('body').addClass('menu');
    });
    $('#menu-close').on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('menu');
    });
    $('#filter-button').on('click', function (event) {
        event.preventDefault();
        $('body').addClass('filter');
    });
    $('#filter-close').on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('filter');
    });
    $('#more-images').on('click', function (event) {
        event.preventDefault();
        $('body').addClass('show-more');
    });
    $('#less-images').on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('show-more');
    });
    $('button').on('click', function (event) {
        let href = $(this).attr('href');

        if (href) {
            event.preventDefault();
            window.location = href;
        }
    });
    $("#form_signUp").closest('form').submit(function(e) {
        if ($("#form_viewing_0:checked").length > 0) {
            let minTimeslots = $('#form_timeslots').data('min');
            let timeslots = $('#form_timeslots input').val();

            let selected = $('#form_timeslots input:checked').length;

            if (minTimeslots > selected) {
                e.preventDefault();

                alert("Select at least " + minTimeslots + " timeslots");
            }

            console.log(minTimeslots);
            console.log(selected);
        }
    });

    $(window).scroll( function(){
        $('#room-signup-inline').each( function(i){
            var topOfObject = $(this).offset().top;
            var scrollTop = $(window).scrollTop();

            if (scrollTop > topOfObject) {
                $('#room-signup').css('display', 'block');
            } else {
                $('#room-signup').css('display', '');
            }

        });
    });
});
